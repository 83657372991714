<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card
        title="Manage Partner Services"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search requests"
              type="text"
            />
            <b-button
              v-b-modal.addRequestModal
              variant="primary"
              @click="title = ''; description = ''; type = ''; instructions = ''; show_instructions = true; affiliate_link = ''; update = false;"
            >
              <feather-icon icon="PlusIcon" />
              Add Partner Service
            </b-button>
          </b-form-group>
        </div>
        <!-- <tour /> -->
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="requests"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'title'"
            >
              {{ props.row.title }}<br>
              <b-link
                style="font-size:14px"
                @click="title = props.row.title; description = props.row.description; type = props.row.type; instructions = props.row.instructions; show_instructions = props.row.show_instructions; affiliate_link = props.row.affiliate_link; $bvModal.show('viewRequestModal')"
              >View Details</b-link>
            </span>
            <span
              v-else-if="props.column.field === 'instructions'"
            >
              <b-badge :variant="`${props.row.show_instructions ? 'primary' : 'light-primary'}`">{{ props.row.show_instructions ? 'On Approval' : 'Everyone' }}</b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex align-items-center"
            >
              <b-button
                size="sm"
                variant="primary"
                class="d-flex align-items-center mr-1"
                :to="{ name: 'partner-services-requests', params: { sid: props.row.id } }"
              >
                View Requests
                <div
                  class="d-flex align-items-center justify-content-center ml-50"
                  style="color:#7367F0; background-color:#fff; border-radius:50%; width: 18px; height: 18px; font-weight: bolder;"
                >{{ props.row.partnerservice_requests_aggregate.aggregate.count }}</div>
              </b-button>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="update = true; selectedRow = props.row.id; title = props.row.title; description = props.row.description; type = props.row.type; instructions = props.row.instructions; show_instructions = props.row.show_instructions; affiliate_link = props.row.affiliate_link; $bvModal.show('addRequestModal')"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                size="sm"
                variant="flat-danger"
                @click="deleteService(props.row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          id="addRequestModal"
          no-close-on-backdrop
          size="md"
          :title="`${update ? 'Update' : 'Add'} Partner Service`"
          :ok-title="`${update ? 'Update' : 'Add'}`"
          @ok="addRequest"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Service Title"
                label-for="Service Title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Service Title"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  placeholder="Description"
                  type="text"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="Type"
              >
                <v-select
                  v-model="type"
                  class="text-capitalize"
                  :options="['Free Credits','Discount','Consultation','Free Service/Product','Other']"
                  placeholder="Choose Type"
                  @input="handleTypeChange"
                />
                <b-form-input
                  v-if="type === 'Other'"
                  v-model="customType"
                  placeholder="Enter Custom Type"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Affiliate Link"
                label-for="Affiliate Link"
              >
                <b-form-input
                  id="affiliate_link"
                  v-model="affiliate_link"
                  placeholder="Affiliate Link"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Instructions"
                label-for="Instructions"
              >
                <b-form-textarea
                  id="instructions"
                  v-model="instructions"
                  placeholder="Instructions"
                  type="text"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="d-flex align-items-center">
                <span>Show Instructions On Approval?</span>
                <b-form-checkbox
                  v-model="show_instructions"
                  class="ml-50"
                  inline
                  switch
                ><span class="switch-icon-left">
                   <feather-icon icon="CheckIcon" />
                 </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span></b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="viewRequestModal"
          no-close-on-backdrop
          ok-only
          size="md"
          title="View Partner Service"
          ok-title="Ok"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Service Title"
                label-for="Service Title"
                disabled
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Service Title"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  placeholder="Description"
                  type="text"
                  rows="2"
                  max-rows="4"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="Type"
              >
                <v-select
                  v-model="type"
                  class="text-capitalize"
                  :options="['Free Credits','Discount','Consultation','Free Service/Product','Other']"
                  placeholder="Choose Type"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Affiliate Link"
                label-for="Affiliate Link"
                disabled
              >
                <b-form-input
                  id="affiliate_link"
                  v-model="affiliate_link"
                  placeholder="Affiliate Link"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Instructions"
                label-for="Instructions"
              >
                <b-form-textarea
                  id="instructions"
                  v-model="instructions"
                  placeholder="Instructions"
                  type="text"
                  rows="2"
                  max-rows="4"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="d-flex align-items-center">
                <span>Show Instructions On Approval?</span>
                <b-form-checkbox
                  v-model="show_instructions"
                  class="ml-50"
                  inline
                  switch
                  disabled
                ><span class="switch-icon-left">
                   <feather-icon icon="CheckIcon" />
                 </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span></b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <!-- <b-modal
          id="assignModal"
          size="md"
          title="Assign Request"
          ok-title="Assign"
          @ok="updateRequestAssignee(requests[selectedRow].requesttable_id, assignee)"
        >
          <b-form-group
            label="Select Assignee"
            label-for="selectAssignee"
          >
            <treeselect
              id="selectAssignee"
              v-model="assignee"
              :options="associations"
              :get-option-label="option => option.users_customuser.full_name"
              :reduce="option => option.id"
              placeholder="Select Assignee"
            />
          </b-form-group>
        </b-modal>
        <b-modal
          v-if="requests[selectedRow] && requests[selectedRow].hasOwnProperty('description') "
          id="requestDetailsModal"
          hide-footer
          no-close-on-backdrop
          title="Request Details"
        >
          <div>
            <p>
              {{ requests[selectedRow].description }}
            </p>
            <hr>
            <div
              v-if="requests[selectedRow].comments && requests[selectedRow].comments.length"
            >
              <span
                class="font-medium-1"
              >Comments</span>
              <div
                v-for="(comment, index) in requests[selectedRow].comments"
                :key="index"
                class="border-top mt-1"
              >
                <p class="mt-1 font-weight-bold d-flex justify-content-between text-capitalize">
                  {{ comment.created_by }}
                  <span><feather-icon icon="CalendarIcon" />
                    {{ comment.created_at }}</span>
                </p>
                <p class="pl-50">
                  {{ comment.message }}
                </p>
              </div>
            </div>
          </div>
        </b-modal> -->
      </b-card>
    </b-overlay>
  </div>
</template>
<script>

import {
  BLink,
  // BCard,
  BBadge,
  BButton,
  // BTabs,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import Tour from '@/views/extensions/tour/Tour.vue'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormInput,
    BLink,
    // BCard,
    BFormTextarea,
    BFormGroup,
    // BTabs,
    BButton,
    VueGoodTable,
    BPagination,
    // Tour,
    BFormSelect,
    BBadge,
    // Treeselect,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      title: '',
      description: '',
      instructions: '',
      type: '',
      customType: '',
      affiliate_link: '',
      show_instructions: true,
      columns: [
        {
          label: 'Service Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Instructions Visibility',
          field: 'instructions',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      requester: null,
      requests: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
      },
      assignee: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    // filteredRows() {
    //   if (!this.requester) return this.requests
    //   // if requester is not $route.params.id, it's raised by incubator
    //   return this.requester !== 'Incubator' ? this.requests.filter(r => r.requester === this.$route.params.id) : this.requests.filter(r => r.requester !== this.$route.params.id)
    // },
  },
  methods: {
    handleTypeChange(value) {
      if (value !== 'Other') {
        this.customType = '' // Reset custom type if 'Other' is not selected
      }
    },
    addRequest() {
      if (this.type === 'Other' && this.customType) {
        this.type = this.customType // Assign custom type to type if 'Other' is selected
      }
      // Apollo mutate add request
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation addRequest {
            ${this.update ? `
              update_partnerservices_by_pk (pk_columns: {id: ${this.selectedRow}}, _set: {title: "${this.title}", description: "${this.description}", instructions: "${this.instructions}", show_instructions: "${this.show_instructions}", affiliate_link: "${this.affiliate_link}", type: "${this.type}", incubator_id: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}){
                id
              }
            ` : `
              insert_partnerservices_one (object: {title: "${this.title}", description: "${this.description}", instructions: "${this.instructions}", show_instructions: "${this.show_instructions}", affiliate_link: "${this.affiliate_link}", type: "${this.type}", incubator_id: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}){
                id
              }
            `}
          },
        `,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Service ${this.update ? 'updated' : 'added'} successfully`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error ${this.update ? 'updating' : 'adding'} request`,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    deleteService(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this service?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_partnerservices_by_pk (pk_columns: {id: ${id}}, _set: {is_deleted: true}){
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.requests.refetch()
                },
              },
            )
          }
        })
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`query partner_services {
          partnerservices(where: {incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}}) {
            id
            incubator_id
            title
            show_instructions
            type
            description
            instructions
            affiliate_link
            partnerservice_requests_aggregate(where: {status: {_nin: "accepted, rejected"}}) {
              aggregate {
                count
              }
            }
          }
        }`
      },
      update: data => data.partnerservices,
    },

    // associations: {
    //   query() {
    //     return gql`
    //     query MyQuery {
    //       programs_partnerstable(where: {program_id: {_eq: ${this.$route.params.id}}}) {
    //         id
    //         users_customuser {
    //           full_name
    //         }
    //       }
    //     }`
    //   },
    //   update: data => data.programs_partnerstable,
    // },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table';
</style>
